import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';

import Header from '../components/layouts/header';
import Layout from '../components/layouts/main';
import SEO from '../components/layouts/seo';
import SectionAbout from '../components/section/about';
import SectionBlog from '../components/section/blog';
import SectionExperience from '../components/section/experience';
import SectionProjects from '../components/section/projects';
import SectionSkills from '../components/section/skills';

const Index = ({ data, location }) => {
  const about = get(data, 'site.siteMetadata.about', false);
  const projects = get(data, 'site.siteMetadata.projects', false);
  const posts = data.allMarkdownRemark.edges;
  const experience = get(data, 'site.siteMetadata.experience', false);
  const skills = get(data, 'site.siteMetadata.skills', false);
  const noProjects = !projects || !projects.length;
  const noBlog = !posts || !posts.length;
  const noExperience = !experience || !experience.length;
  const noSkills = !skills || !skills.length;

  return (
    <Layout>
      <SEO location={location} />
      <Header metadata={data.site.siteMetadata} noBlog={noBlog} />
      {about && <SectionAbout about={about} />}
      {!noProjects && <SectionProjects projects={projects} />}
      {!noExperience && <SectionExperience experience={experience} />}
      {!noSkills && <SectionSkills skills={skills} />}
      {!noBlog && <SectionBlog posts={posts} />}
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        twitter
        github
        linkedin
        devto
        stackoverflow
        leetcode
        discord
        email
        projects {
          name
          description
          link
          github
        }
        experience {
          name
          description
          link
        }
        skills {
          name
          description
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
